import React from 'react'
import Img from 'gatsby-image'
import Link from '../components/Link'

import styles from './PostLink.module.scss'

const ArticleLink = ({slug, children}) => {
  return (
    <Link to={`/blog/${slug}`}>{children}</Link>
  )
}

const PostLink = ({post, context}) => {

  const contextEl = context ? <span className={styles.context}>{context}</span> : ''

  return (
    <div key={post.id} className={styles.post}>
      {post.frontmatter.banner && (
        <ArticleLink slug={post.frontmatter.slug}>
         <div className={styles.imageWrapper}>
           {contextEl}
           <Img className={styles.img}
                sizes={post.frontmatter.banner.childImageSharp.sizes}/>
         </div>

        </ArticleLink>
      )}

      <div className={styles.content}>
        <small className={styles.date}>{post.frontmatter.date}</small>
        <h2 className={styles.title}>
          <ArticleLink slug={post.frontmatter.slug}>
            {post.frontmatter.title}
          </ArticleLink>
        </h2>
        <p className={styles.excerpt}>{post.excerpt}</p>
      </div>
    </div>
  )
}

export default PostLink
