import React, {Fragment} from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/Layout'
import Link from '../components/Link'
import PostLink from '../components/PostLink'

import styles from './blog.module.scss'
import Header from '../components/Header'

const Categories = ({categories}) => (
  <Fragment>
    <div className={styles.categories}>
      <Link to={`/blog/`}>
        <img src={'/img/grid-icon.svg'} alt="icon for all categories" title="all categories" className={styles.icon}/>
      </Link>
      <ul className="unstyled">
        {categories.map(category => (
          <li key={category}><Link to={`/blog/categories/${category}`}>
            {category}
          </Link>
          </li>
        ))}
      </ul>
    </div>
  </Fragment>
)

const Blog = ({
                data: {allMdx},
                pageContext: {pagination, categories}
              }) => {
  const {page, nextPagePath, previousPagePath} = pagination

  const posts = page.map(id =>
    allMdx.edges.find(edge => edge.node.id === id)
  )

  const description = 'Tutorials, inspiration and news around graphic and web design'

  return (
    <Layout title="Blog" description={description}>
      <Header title={'SceneLab Blog'} sub={description}/>
      <div className="content-padding">
        <div className="content-container">
          <Categories categories={categories}/>
          <div className={styles.posts}>
            {posts.map(({node: post}) => (
              <PostLink key={post.id} post={post}/>
            ))}

          </div>
          <div className={styles.pagination}>
            {nextPagePath && (
              <button className={styles.prev}>
                <Link to={nextPagePath}>Next Page</Link>
              </button>
            )}

            {previousPagePath && (
              <button>
                <Link to={previousPagePath}>Previous Page</Link>
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          excerpt(pruneLength: 140)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
              childImageSharp {
                sizes(maxHeight: 200, quality: 100) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
            slug
            categories
            keywords
          }
        }
      }
    }
  }
`
